.accueil__description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 5%;
}

@media screen and (min-width: 1024px) {
  .accueil {
    width: 80%;
  }
  .accueil__description {
    margin-bottom: 3%;
  }
}