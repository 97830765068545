.centre__accueil {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.centre__accueil_pic {
  object-fit: contain;
}

.centre__accueil_pic .content__image {
  max-height: none;
}

.centre__fundarising {
  display: flex;
  flex-direction: column;
  margin-bottom: 10%;
}

.centre__contact_content {
  font-size: 1.2rem;
  font-weight: normal;
}

.centre__contact_name {
  font-family: 'Handlee', cursive;
  font-weight: bold;
}

.centre__contact_phone {
  padding-left: 5px;
}

.centre__contact_phone-number {
  color: #1A1B22;
  outline: none;
}
.centre__contact_phone-number:visited {
  text-decoration: none;
}

@media screen and (max-width: 320px) {
  .centre__contact_content {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 768px) {
  .centre__contact_content {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 1024px) {
  .centre__contact_content {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1280px) {
  .centre__accueil {
    flex-direction: row;
    column-gap: 5%;
    justify-content: space-evenly;
  }

  .centre__accueil_pic {
    flex-grow: 4;
  }

  .centre__accueil_pic .content__image {
    min-height: 400px;
    max-height: none;
  }

  .centre__contact_content {
    font-size: 1.8rem;
  }
}