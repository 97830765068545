@import '~@csstools/normalize.css';
@import url('./vendor/fonts/font.css');


body {
  margin: 0;
  padding: 0;

  font-family: 'Roboto Slab', 'Lobster Two', 'The Nautigal', 'Roboto', 'Ubuntu', 'Cantarell',
              'Courier New', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, monospace, cursive;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.15;
  color: #000000;
  text-rendering: optimizeLegibility;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

html, body {
  height: 100%;
}


#root {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  object-fit: cover;
  overflow-y: scroll;
}

.app_content {
  flex: 1 0 auto;
}

a:link {
  text-decoration: none;
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 320px) {
  body {
    font-size: 1rem;
  }
}

@media screen and (min-width: 768px) {
  body {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1366px) {
  body {
    font-size: 2rem;
  }
}