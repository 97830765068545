.header {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2% 0 5%;
  margin: 0;

  background-image: url("../../images/logos/header_banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: content-box;
}

.header__menus {
  display: flex;
  justify-content: space-between;
}

.header__menus_details {
  display: flex;
}

.header__language {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

  font-family: 'Roboto Slab', 'Arial', sans-serif;
  font-style: normal;
  cursor: pointer;
}
.lang-tooltip {
  position: absolute;
  bottom: 60%;
  right: 2%;
  padding: 5px;
  background-color: #333;
  border-radius: 3px;
  font-size: 0.8rem;
  color: #eee;
  text-align: center;
  z-index: 1;
}
.lang-tooltip::after {
  position: absolute;
  content: "";
  bottom: 100%;
  left: 55%;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
  border-width: 2vw;
}

.header__language_icon {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 15%;
  right: 15%;
}

.header__language_list {
  position: absolute;
  display: none;
  top: 15%;
  right: 2%;
  padding: 5px;
  background-color: #EDE9D5;
  /* background-color: #025955; */
  color: #0a0a0afb;
  list-style-type: none;
  z-index: 1;
}
.header__language_list:before {
  content: "";
  border: 1px solid #E14D2A;
  align-self: stretch;
}
#english {
  order: -1;
}

.header__language_option {
  padding: 5px;
  margin-right: 0;
  font-size: 5vw;
}

.header__text {
  margin: 0 2vh 2vh 2vh;
  font-family: 'Neuton', serif;
  font-size: 2.2rem;
  font-style: italic;
  font-weight: 700;
  text-align: center;
  color: #F8FFF8;
}
@media screen and (max-width: 300px) {
  .lang-tooltip {
    bottom: 65%;
    right: 0;
    padding: 3px;
    font-size: 0.7rem;
  }
  .lang-tooltip::after {
    left: 50%;
    border-width: 3vw;
  }
  .header__language_icon {
    width: 24px;
    height: 24px;
  }
  .header__text {
    font-size: 2rem;
  }
}
@media screen and (min-width: 400px) {
  .lang-tooltip::after {
    left: 50%;
  }
}

@media screen and (min-width: 768px) {
  .header {
    padding: 0;
  }
  .header__menus {
    justify-content: space-between;
  }
  .header__menus_details {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .header__language {
    justify-content: center;
    padding: 0 10px;
    font-size: 1.3rem;
  }
  .lang-tooltip {
    bottom: 45%;
  }
  .lang-tooltip::after {
    left: 75%;
  }
  .header__language_icon {
    position: relative;
    top: 0;
  }
  .header__language_list {
    top: 10%;
    right: 0;
  }
  .header__language_option {
    font-size: 3vw;
  }
  .header__text {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .header__language {
    font-size: 1.5rem;
  }
  .lang-tooltip {
    bottom: 62%;
    right: 0;
    font-size: 1.1rem;
  }
  .lang-tooltip::after {
    border-width: 1vw;
  }
  .header__language_list {
    top: 2%;
  }
  .header__language_option {
    padding: 5px 10px;
    margin-right: 0;
  }
  .header__text {
    margin: 2vh 8%;
    font-size: 4rem;
  }
}

@media screen and (min-width: 1366px) {
  .header__text {
    font-size: 5rem;
  }
}