.preloader {
  width: 100%;
  margin: 0;
  padding: 32px 0;
}

.preloader__circle{
	display: block;
	width: 50px;
	height: 50px;
  margin: auto;

	border: 4px solid #444;
	border-bottom-color: #888;
	border-radius: 50%;

	animation: spin .75s infinite linear;
}

.preloader__paragraph {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 133%;

  color: #B6BCBF;
  text-align: center;
}


@media screen and (min-width: 768px) {
  .preloader {
    padding: 40px 0;
  }
  .preloader__circle {
    width: 74px;
    height: 74px;
  }
}

@media screen and (min-width: 1024px) {
  .preloader {
    padding: 80px 0;
  }
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}