.logo {
  width: 160px;
  height: 100px;
  cursor: pointer;
  z-index: 1;
}

.logo_place_footer {
  width: 100px;
  height: 50px;
}

.logo_place_header {
  position: relative;
  top: 0;
  left: 2%;
}

@media screen and (min-width: 1024px) {
  .logo {
    width: 250px;
    height: 100px;
  }

  .logo_place_footer {
    width: 120px;
    height: 60px;
  }
}