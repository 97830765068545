.slider {
  height: 55vh;
  display: flex;
  margin-bottom: 5%;
}

.slide {
  margin: 0 auto;
  /* object-fit: scale-down; */
  /* gives a shadow to images to highlight where image has same color as its parent's background */
  filter:
    drop-shadow(
      1px 2px 8px hsl(200deg 50% 40% / 0.2)
    )
    drop-shadow(
      2px 4px 16px hsl(200deg 50% 40% / 0.2)
    )
}

@media screen and (min-width: 600px) {
  .slider {
    height: 60vh;
    margin-bottom: 0%;
  }
  .slide {
    height: 90%;
    object-fit: cover;
  }
}

@media screen and (min-width: 1366px) {
  .slider a.previousButton {
    left: 15%;
    top: 35%;
  }
  .slider a.nextButton {
    right: 15%;
    top: 35%;
  }
}




/*  Below code is for refernce
    To be used in case necessary to override the CSS defined in the react-slider-component

 .slider {
	position: relative;
	width: 100%;
	height: 400px;
	overflow: hidden
}

.slider a.previousButton, .slider a.nextButton {
	font-size: 22px;
	line-height: 0;
	display: block;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	-webkit-transition: all .3s linear;
	transition: all .3s linear;
	z-index: 1;
	color: #333;
	padding: 10px;
	text-decoration: none;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden
	/* prevent jump effect when scaling */
/* }

.slider a.previousButton:not(.disabled):hover, .slider a.nextButton:not(.disabled):hover {
	-webkit-transform: translateY(-50%) scale(1.25);
	        transform: translateY(-50%) scale(1.25);
	cursor: pointer;
}

.slider a.previousButton {
	left: 20px;
}

.slider a.nextButton {
	right: 20px;
}

.slide {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden
}

.slide.hidden {
	visibility: hidden;
}

.slide.previous {
	left: -100%;
}

.slide.current {
	left: 0;
}

.slide.next {
	left: 100%;
}

.slide.animateIn,
	.slide.animateOut {
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}

.slide.animateIn.previous,
		.slide.animateIn.next {
	left: 0;
	visibility: visible;
}

.slide.animateOut.previous {
	left: 100%;
}

.slide.animateOut.next {
	left: -100%;
} */