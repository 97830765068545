.gallery__photos {
  display: flex;
  flex-direction: column;
  margin: 10% auto;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 5% 0;
  background-color: #A2AF9F;
  list-style-type: none;
}

.cards__item {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 10px;
  margin: 0 auto;

  font-weight: bold;
  line-height: 150%;
  color: #FFFFFF;
  cursor: pointer;
  text-align: center;
}

.cards__photo {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  object-fit: cover;
  border-radius: 5%;
}

.popup {
  visibility: hidden;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  margin: 0;
  padding: 0;

  background: rgba(0, 0, 0, .5);
  opacity: 0;
  transition: visibility 0s .3s, opacity .5s linear;
  /* z-index compensation of the card delete icon */
  z-index: 10;
}
.popup_open {
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

.popup__content {
  position: relative;
  width: 80%;
  margin: 0;
  padding: 0;
  top: 0;
}

.popup__close {
  top: -8%;
  right: -13%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  height: 28px;
  width: 28px;

  background: url('../../images/logos/close-icon.png') center no-repeat;
  border: 0;
}
.popup__close:hover {
  opacity: .6;
}

.popup__image {
  object-fit: cover;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 300px;
  max-width: 85vw;
  max-height: 85vh;
  box-sizing: border-box;
}

@media screen and (min-width: 600px) {
  .element {
    row-gap: 15px;
  }
  .gallery__photos {
    margin: 5% auto;
  }
  .cards {
    padding: 0;
  }
  .cards__item {
    width: 220px;
    margin: 0 auto 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cards__photo {
    height: 180px;
    width: 180px;
  }
}

@media screen and (min-width: 768px) {
  .popup__content {
    width: 433px;
  }
  .popup__close {
    height: 40px;
    width: 40px;
    right: -10%;
    top: -7%;
  }
  .popup__image {
    height: 540px;
  }
}

@media screen and (min-width: 1024px) {
  .cards__item {
    width: 240px;
  }
  .cards__photo {
    height: 200px;
    width: 200px;
  }
  .popup__content {
    width: 816px;
  }
  .popup__close {
    right: 0;
  }
}

@media screen and (min-width: 1280px) {
  .popup__close {
    right: -5%;
  }
}